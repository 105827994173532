import classNames from 'classnames/bind';
import Link from 'components/shared/link';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './arrow-link.module.scss';
import ArrowIcon from './images/arrow.inline.svg';

const cx = classNames.bind(styles);

const ArrowLink = ({ className, children, ...otherProps }) => (
  <Link className={cx('wrapper', className)} {...otherProps}>
    {children}
    <ArrowIcon className={cx('arrow')} />
  </Link>
);

ArrowLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

ArrowLink.defaultProps = {
  className: null,
};

export default ArrowLink;
