import classNames from 'classnames/bind';
import Mdx from 'components/containers/mdx';
import AnimationLine from 'components/shared/animation-lines';
import { m, LazyMotion, domAnimation, useAnimation } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './code-examples.module.scss';

const cx = classNames.bind(styles);

const codeWrapperVariants = {
  initial: {
    translateY: -20,
    opacity: 0,
  },
  animate: {
    translateY: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.35, 0.35, 0, 1],
    },
  },
};

const CodeExamples = () => {
  const {
    allMdx: { nodes: items },
  } = useStaticQuery(graphql`
    query {
      allMdx(filter: { fileAbsolutePath: { regex: "/data/home-examples/" } }) {
        nodes {
          body
          frontmatter {
            name
          }
        }
      }
    }
  `);
  const [codeWrapperRef, isCodeWrapperInView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const codeWrapperControls = useAnimation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const shouldCodeWrapperBeAnimated = typeof window !== 'undefined' && window.matchMedia('(min-width: 1024px)').matches;

  useEffect(() => {
    if (isCodeWrapperInView) codeWrapperControls.start('animate');
  }, [isCodeWrapperInView, codeWrapperControls]);

  return (
    <section className={cx('wrapper', 'safe-paddings')} ref={shouldCodeWrapperBeAnimated ? codeWrapperRef : undefined}>
      <div className="container-sm-v2">
        <h2 className={cx('title')}>Script tests in Javascript</h2>
        <LazyMotion features={domAnimation}>
          <m.div
            className={cx('code-tabs-wrapper', 'container')}
            initial="initial"
            animate={shouldCodeWrapperBeAnimated ? codeWrapperControls : undefined}
            variants={shouldCodeWrapperBeAnimated ? codeWrapperVariants : undefined}
          >
            <div className={cx('code-tabs')}>
              <ul className={cx('code-tabs-buttons')}>
                {items.map(({ frontmatter: { name } }, index) => (
                  <li className={cx('code-tabs-button-wrapper')} key={index}>
                    <button
                      className={cx('code-tabs-button', index === activeTabIndex && 'active')}
                      type="button"
                      onClick={() => setActiveTabIndex(index)}
                    >
                      {name}
                    </button>
                  </li>
                ))}
              </ul>
              <div className={cx('code-tabs-code-wrapper')} aria-hidden>
                {
                  items.map(({ body }, index) => (
                    <Mdx className={cx('code-tabs-code')} mode="plain" content={body} key={index} />
                  ))[activeTabIndex]
                }
              </div>
            </div>
          </m.div>
        </LazyMotion>
        <AnimationLine />
      </div>
    </section>
  );
};

export default CodeExamples;
