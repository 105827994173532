import { Button } from 'components/shared/button';
import { Heading } from 'components/shared/heading';
import React from 'react';

import styles from './home-hero.module.scss';
import animationVideo from './videos/home-page-main.mp4';

export const Hero = () => (
  <section className={styles.wrapper}>
    <div className={`container ${styles.inner}`}>
      <div className={'row'}>
        <div className={'col-lg-6 col-12'}>
          <Heading className={styles.title} tag={'h1'}>
            The best developer <br /> experience for load testing
          </Heading>
          <p className={styles.description}>Open source and SaaS for engineering teams</p>
          <div className={`${styles.buttonsWrapper} hide-md-down`}>
            <Button className={styles.button} tag={'link'} to={'/open-source'} theme={'additional'} cursor>
              Open Source
            </Button>
            <Button
              className={styles.button}
              tag={'link'}
              href={'https://grafana.com/products/cloud/k6/?src=k6io'}
              cursor
            >
              Grafana Cloud k6
            </Button>
          </div>
        </div>
        <div className={'col-lg-6 d-flex justify-content-center'}>
          <div
            className={styles.aspectRatioBox}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line max-len
              __html: `<video class="${styles.illustration}" src="${animationVideo}" type="video/mp4" autoPlay loop muted playsinline/>`, // Because React doesn't support attribute 'muted'. Nice, huh? https://github.com/facebook/react/issues/10389
            }}
          />
        </div>

        <div className={'col-lg-12 show-md-down'}>
          <div className={styles.buttonsWrapper}>
            <Button className={styles.button} tag={'link'} to={'/cloud'} cursor>
              Cloud
            </Button>
            <Button className={styles.button} tag={'link'} to={'/open-source'} theme={'additional'} cursor>
              Open Source
            </Button>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
