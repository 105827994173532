import classNames from 'classnames/bind';
import AnimationLine from 'components/shared/animation-lines';
import React from 'react';

import BrainIcon from './images/brain.inline.svg';
import ChaosIcon from './images/chaos.inline.svg';
import CloudCogIcon from './images/cloud-cog.inline.svg';
import EndToEndIcon from './images/end-to-end.inline.svg';
import RegressionIcon from './images/regression.inline.svg';
import SpeedometerIcon from './images/speedometer.inline.svg';
import styles from './in-one-place.module.scss';

const cx = classNames.bind(styles);

const items = [
  {
    icon: SpeedometerIcon,
    title: 'Load testing',
    description:
      // eslint-disable-next-line max-len
      'Verify that applications can handle the expected traffic. Different goals require different tests: <a class="link" href="https://grafana.com/docs/k6/latest/testing-guides/test-types/stress-testing/">stress tests</a>, <a class="link" href="https://grafana.com/docs/k6/latest/testing-guides/test-types/spike-testing/">spike tests</a>, <a class="link" href="https://grafana.com/docs/k6/latest/testing-guides/test-types/soak-testing/">soak tests</a>, <a class="link" href="https://grafana.com/docs/k6/latest/testing-guides/test-types/smoke-testing/">smoke tests</a>, etc.',
  },
  {
    icon: EndToEndIcon,
    title: 'End-to-end web testing',
    description:
      // eslint-disable-next-line max-len
      '<a class="link" href="/hybrid-performance-testing">Mix browser and API testing</a>—interact with real browsers and collect frontend metrics to get a holistic user view.',
  },
  {
    icon: BrainIcon,
    title: 'Synthetic monitoring',
    description:
      // eslint-disable-next-line max-len
      'Traditional ping testing is not enough anymore. Reuse your k6 tests with <a class="link" href="https://grafana.com/products/cloud/synthetic-monitoring/?src=k6io">Synthetic Monitoring</a> to continuously verify production environments.',
  },
  {
    icon: ChaosIcon,
    title: 'Fault injection testing',
    description:
      // eslint-disable-next-line max-len
      '<a class="link" href="/fault-injection-testing">Inject faults in Kubernetes-based apps</a> to recreate application errors. Test resilience patterns and tolerance of internal errors to improve reliability.',
  },
  {
    icon: CloudCogIcon,
    title: 'Infrastructure testing',
    description:
      // eslint-disable-next-line max-len
      'Test how cloud-native systems scale. Isolate bottlenecks. Plan and provision infrastructure capacity.',
  },
  {
    icon: RegressionIcon,
    title: 'Regression testing',
    description:
      // eslint-disable-next-line max-len
      'Test continuously to track changes in performance and reliability. Prevent software regressions from reaching production.',
  },
];

const InOnePlace = () => (
  <section className={cx('wrapper', 'safe-paddings')}>
    <div className="container">
      <div className={cx('inner')}>
        <h2 className={cx('title')}>More testing with one tool</h2>
        <p className={cx('description')}>
          k6 Open Source is designed for load testing. Extensible to support other types of testing.
        </p>
        <ul className={cx('items', 'grid-gap-x')}>
          {items.map(({ icon: Icon, title, description }, index) => (
            <li className={cx('items-item-wrapper')} key={index}>
              <div className={cx('items-item')}>
                <Icon className={cx('items-item-icon')} />
                <h3 className={cx('items-item-title')}>{title}</h3>
                {/* eslint-disable-next-line react/no-danger */}
                <p className={cx('items-item-description')} dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </li>
          ))}
        </ul>
      </div>
      <AnimationLine />
    </div>
  </section>
);

export default InOnePlace;
