import classNames from 'classnames/bind';
import ArrowLink from 'components/shared/arrow-link';
import ImagePlaceholder from 'components/shared/image-placeholder';
import { m, LazyMotion, domAnimation, useAnimation } from 'framer-motion';
import { useLottie } from 'hooks';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import linesAnimationData from './assets/lines-animation-data.json';
import styles from './for-engineering-teams.module.scss';
import DeveloperIcon from './images/developer.inline.svg';
import DevopsIcon from './images/devops.inline.svg';
import SoftwareTestersIcon from './images/software-testers.inline.svg';

const cx = classNames.bind(styles);

const items = [
  {
    icon: DeveloperIcon,
    title: 'Developers',
    description:
      // eslint-disable-next-line max-len
      'Backend and frontend engineers prevent regressions when running performance tests.',
  },
  {
    icon: DevopsIcon,
    title: 'Site Reliability Engineers',
    description:
      // eslint-disable-next-line max-len
      'Test scalability to improve your reliability targets. Test SLOs and avoid SLA breaches.',
  },
  {
    icon: SoftwareTestersIcon,
    title: 'Software Engineers in Test / QA',
    description:
      // eslint-disable-next-line max-len
      'Shift left and collaborate with developers for effective and continuous testing.',
  },
];

const itemsVariants = {
  initial: {},
  animate: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemsItemVariants = {
  initial: {
    translateY: -20,
    opacity: 0,
  },
  animate: {
    translateY: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.35, 0.35, 0, 1],
    },
  },
};

const ForEngineeringTeams = () => {
  const [itemsRef, isItemsInView] = useInView({ triggerOnce: true, threshold: 0.8 });
  const itemsControls = useAnimation();

  useEffect(() => {
    if (isItemsInView) itemsControls.start('animate');
  }, [isItemsInView, itemsControls]);

  const [linesWrapperRef, isLinesWrapperInView] = useInView({ triggerOnce: true, threshold: 0.8 });
  const [linesAnimation, linesAnimationRef] = useLottie({ animationData: linesAnimationData });

  useEffect(() => {
    if (isLinesWrapperInView) {
      linesAnimation.playSegments([0, 30], true);
      setTimeout(() => linesAnimation.playSegments([30, 60], true), 2000);
    }
  }, [isLinesWrapperInView, linesAnimation]);

  const shouldItemsBeAnimated = typeof window !== 'undefined' && window.matchMedia('(min-width: 1024px)').matches;

  return (
    <section className={cx('wrapper', 'safe-paddings')}>
      <div className="container">
        <h2 className={cx('title')}>Prevent failures. Improve reliability. Release with confidence.</h2>
        <p className={cx('description')}>Test early and continuously—break the QA silo in performance testing</p>
        <LazyMotion features={domAnimation}>
          <m.ul
            className={cx('items', 'grid-gap-x')}
            initial="initial"
            animate={shouldItemsBeAnimated ? itemsControls : undefined}
            variants={shouldItemsBeAnimated ? itemsVariants : undefined}
            ref={shouldItemsBeAnimated ? itemsRef : undefined}
          >
            {items.map(({ icon: Icon, title, description }, index) => (
              <m.li
                className={cx('items-item')}
                variants={shouldItemsBeAnimated ? itemsItemVariants : undefined}
                key={index}
              >
                <Icon className={cx('items-item-icon')} />
                <h3 className={cx('items-item-title')}>{title}</h3>
                <p className={cx('items-item-description')}>{description}</p>
              </m.li>
            ))}
          </m.ul>
        </LazyMotion>
        <div className={cx('view-all-link-wrapper')}>
          <ArrowLink to="https://k6.io/modern-load-testing-for-engineering-teams/">All team testing</ArrowLink>
        </div>
        <div className={cx('lines-wrapper')} ref={linesWrapperRef}>
          <div className={cx('lines-1', 'lines')} ref={linesAnimationRef} />
          <ImagePlaceholder width={820} height={228} />
        </div>
        <div className={cx('line')} />
      </div>
    </section>
  );
};

export default ForEngineeringTeams;
