import { SEO } from 'components/blocks/seo';
import AnyScale from 'components/pages/home/any-scale';
import CodeExamples from 'components/pages/home/code-examples';
import ForEngineeringTeams from 'components/pages/home/for-engineering-teams';
import { Hero } from 'components/pages/home/hero';
import InOnePlace from 'components/pages/home/in-one-place';
import IntegrationsExtensions from 'components/pages/home/integrations-extensions';
import Testimonials from 'components/pages/home/testimonials';
import { Partners } from 'components/shared/partners';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

const HomePage = () => (
  <DefaultLayout>
    <Hero />
    <Partners title={'Loved by developers. Trusted by businesses'} />
    <ForEngineeringTeams />
    <CodeExamples />
    <AnyScale />
    <InOnePlace />
    <IntegrationsExtensions />
    <Testimonials />
  </DefaultLayout>
);

export default HomePage;

export const Head = () => <SEO {...SEO_DATA.index} />;
