import classNames from 'classnames/bind';
import { m, LazyMotion, domAnimation, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './animation-line.module.scss';
import Line from './images/line.inline.svg';

const cx = classNames.bind(styles);

const circleVariants = {
  initial: {
    scale: 0,
    top: 0,
    translateY: 0,
    translateX: '-50%',
    width: 16,
    height: 16,
    borderWidth: 1,
  },
  animate: ({ top, translateY }) => ({
    scale: 1,
    top,
    translateY,
    translateX: '-50%',
    width: 16,
    height: 16,
    borderWidth: 1,
    transition: {
      duration: 1,
      ease: [0.35, 0.35, 0, 1],
      scale: {
        duration: 0.5,
        ease: 'linear',
      },
      width: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.35, 0.35, 0, 1],
      },
      height: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.35, 0.35, 0, 1],
      },
      borderWidth: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.35, 0.35, 0, 1],
      },
    },
  }),
  exit: {
    scale: 0,
    top: '100%',
    width: 16,
    height: 16,
    translateY: 0,
    translateX: '-50%',
    borderWidth: 3,
    transition: {
      duration: 1,
      ease: [0.35, 0.35, 0, 1],
      scale: {
        delay: 0.7,
        duration: 0.3,
        ease: 'linear',
      },
      width: {
        duration: 0.3,
        ease: [0.35, 0.35, 0, 1],
      },
      height: {
        duration: 0.3,
        ease: [0.35, 0.35, 0, 1],
      },
      borderWidth: {
        duration: 0.3,
        ease: [0.35, 0.35, 0, 1],
      },
    },
  },
};

const AnimationLine = () => {
  const [sectionRef, isSectionInView] = useInView({ triggerOnce: true, threshold: 0.8 });
  const controls = useAnimation();

  useEffect(() => {
    if (isSectionInView) {
      controls.start('animate');
      setTimeout(() => controls.start('exit'), 2000);
    }
  }, [isSectionInView, controls]);

  return (
    <div ref={sectionRef} className={cx('line-wrapper')}>
      <LazyMotion features={domAnimation}>
        <m.div initial="initial" animate={controls}>
          <m.div
            className={cx('circle', 'circle-1')}
            custom={{ top: '50%', translateY: '-50%' }}
            variants={circleVariants}
          />
          <m.div />
        </m.div>
        <Line className={cx('line')} />
      </LazyMotion>
    </div>
  );
};

export default AnimationLine;
