import classNames from 'classnames/bind';
import ArrowLink from 'components/shared/arrow-link';
import Link from 'components/shared/link';
import { m, LazyMotion, domAnimation, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import amazonSqs from './images/amazon-sqs.svg';
import apacheKafkaLogo from './images/apache-kafka.svg';
import CircleIcon2 from './images/circle-2.inline.svg';
import CircleIcon from './images/circle.inline.svg';
import datadogLogo from './images/datadog.svg';
import googlePubSub from './images/google-pub-sub.svg';
import grafanaLogo from './images/grafana.svg';
import intelliJideaLogo from './images/intellij-idea.svg';
import kubernetes from './images/kubernetes.svg';
import LinesMd from './images/lines-md.inline.svg';
import Lines2Md from './images/lines2-md.inline.svg';
import Lines from './images/lines2.inline.svg';
import newRelicLogo from './images/new-relic.svg';
import openTelemetry from './images/open-telemetry.svg';
import postmanLogo from './images/postman.svg';
import prometheus from './images/prometheus.svg';
import visualStudioLogo from './images/visual-studio.svg';
import styles from './integrations-extensions.module.scss';

const cx = classNames.bind(styles);

const circleVariants = {
  initial: {
    scale: 0,
    top: 0,
    translateY: 0,
    translateX: '-50%',
    width: 16,
    height: 16,
    borderWidth: 3,
  },
  animate: ({ top, translateY }) => ({
    scale: 1,
    top,
    translateY,
    translateX: '-50%',
    width: 56,
    height: 56,
    borderWidth: 8,
    transition: {
      duration: 1,
      ease: [0.35, 0.35, 0, 1],
      scale: {
        duration: 0.5,
        ease: 'linear',
      },
      width: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.35, 0.35, 0, 1],
      },
      height: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.35, 0.35, 0, 1],
      },
      borderWidth: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.35, 0.35, 0, 1],
      },
    },
  }),
  exit: {
    scale: 0,
    top: '100%',
    width: 16,
    height: 16,
    translateY: 0,
    translateX: '-50%',
    borderWidth: 3,
    transition: {
      duration: 1,
      ease: [0.35, 0.35, 0, 1],
      scale: {
        delay: 0.7,
        duration: 0.3,
        ease: 'linear',
      },
      width: {
        duration: 0.3,
        ease: [0.35, 0.35, 0, 1],
      },
      height: {
        duration: 0.3,
        ease: [0.35, 0.35, 0, 1],
      },
      borderWidth: {
        duration: 0.3,
        ease: [0.35, 0.35, 0, 1],
      },
    },
  },
};

const circleIconVariants = {
  initial: {
    scale: 0,
  },
  animate: {
    scale: 1,
    transition: {
      delay: 0.5,
      duration: 0.5,
      ease: [0.35, 0.35, 0, 1],
    },
  },
  exit: {
    scale: 0,
    transition: {
      duration: 0.3,
      ease: [0.35, 0.35, 0, 1],
    },
  },
};

const integrations = [
  {
    logo: postmanLogo,
    name: 'Postman',
    to: 'https://k6.io/blog/load-testing-with-postman-collections',
  },
  {
    logo: newRelicLogo,
    name: 'New Relic',
    to: 'https://grafana.com/docs/k6/latest/results-output/real-time/new-relic/',
  },
  {
    logo: visualStudioLogo,
    name: 'Visual Studio',
    to: 'https://grafana.com/docs/k6/latest/misc/integrations/#ide-extensions',
  },
  {
    logo: grafanaLogo,
    name: 'Grafana',
    to: 'https://grafana.com/docs/k6/latest/results-output/grafana-dashboards/',
  },
  {
    logo: intelliJideaLogo,
    name: 'IntelliJ IDEA',
    to: 'https://grafana.com/docs/k6/latest/misc/integrations/#ide-extensions',
  },
  {
    logo: datadogLogo,
    name: 'Datadog',
    to: 'https://grafana.com/docs/k6/latest/results-output/real-time/datadog/',
  },
];

const extensions = [
  {
    logo: kubernetes,
    name: 'Kubernetes',
    to: 'https://github.com/grafana/xk6-kubernetes',
  },
  {
    logo: prometheus,
    name: 'Prometheus',
    to: 'https://grafana.com/docs/k6/latest/results-output/real-time/prometheus-remote-write/',
  },
  {
    logo: googlePubSub,
    name: 'Pub/Sub',
    to: 'https://github.com/olvod/xk6-pubsub',
  },
  {
    logo: amazonSqs,
    name: 'Amazon SQS',
    to: 'https://github.com/mridehalgh/xk6-sqs',
  },
  {
    logo: apacheKafkaLogo,
    name: 'Kafka',
    to: 'https://github.com/mostafa/xk6-kafka',
  },
  {
    logo: openTelemetry,
    name: 'OpenTelemetry',
    to: 'https://github.com/grafana/xk6-distributed-tracing',
  },
];

const IntegrationsExtensions = () => {
  const [sectionRef, isSectionInView] = useInView({ triggerOnce: true, threshold: 0.8, delay: 2500 });
  const controls = useAnimation();

  useEffect(() => {
    if (isSectionInView) {
      controls.start('animate');
      setTimeout(() => controls.start('exit'), 2000);
    }
  }, [isSectionInView, controls]);

  return (
    <section className={cx('wrapper', 'safe-paddings')}>
      <div className="container-v2">
        <h2 className={cx('title')}>Integrations and Extensions</h2>
        <p className={cx('description')}>k6 integrates with your dev tools. Extensions add new capabilities to k6.</p>
        <LazyMotion features={domAnimation}>
          <div className={cx('all-items-wrapper')} ref={sectionRef}>
            <div className={cx('items-wrapper')}>
              <ul className={cx('items', 'items-1')}>
                {integrations.map(({ logo, name, to }, index) => (
                  <li key={index}>
                    <Link className={cx('items-item')} to={to}>
                      <div className={cx('items-item-corner')} />
                      <div className={cx('items-item-inner')}>
                        <img
                          className={cx('items-item-logo')}
                          height={44}
                          width={44}
                          src={logo}
                          alt=""
                          loading="lazy"
                        />
                        <p className={cx('items-item-name')}>{name}</p>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
              <div className={cx('view-all-link-wrapper')}>
                <ArrowLink to="https://grafana.com/docs/k6/latest/misc/integrations/">View all integrations</ArrowLink>
              </div>
            </div>

            <m.div className={cx('lines-wrapper')} initial="initial" animate={controls}>
              <Lines className={cx('lines')} />

              <m.div
                className={cx('circle', 'circle-1')}
                custom={{ top: '50%', translateY: '-50%' }}
                variants={circleVariants}
              >
                <m.div variants={circleIconVariants}>
                  <CircleIcon className={cx('circle-inner')} />
                </m.div>
              </m.div>
            </m.div>

            <CircleIcon2 className={cx('line-1')} />

            <div className={cx('items-wrapper')}>
              <ul className={cx('items', 'items-2')}>
                {extensions.map(({ logo, name, to }, index) => (
                  <li key={index}>
                    <Link className={cx('items-item')} to={to}>
                      <div className={cx('items-item-corner')} />
                      <div className={cx('items-item-inner')}>
                        <img
                          className={cx('items-item-logo')}
                          height={44}
                          width={44}
                          src={logo}
                          alt=""
                          loading="lazy"
                        />
                        <p className={cx('items-item-name')}>{name}</p>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
              <div className={cx('view-all-link-wrapper')}>
                <ArrowLink to="https://grafana.com/docs/k6/latest/extensions/">View all extensions</ArrowLink>
              </div>
            </div>
          </div>
        </LazyMotion>
        <LinesMd className={cx('lines-md')} />
        <Lines2Md className={cx('line-2')} />
      </div>
    </section>
  );
};

export default IntegrationsExtensions;
