import classNames from 'classnames/bind';
import AnimationLine from 'components/shared/animation-lines';
import ArrowLink from 'components/shared/arrow-link';
import Link from 'components/shared/link';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import TwitterLogo from './images/twitter.inline.svg';
import styles from './testimonials.module.scss';

const cx = classNames.bind(styles);

const Testimonials = () => {
  const {
    allTweetsJson: { nodes: items },
  } = useStaticQuery(graphql`
    query {
      allTweetsJson(limit: 6, skip: 1) {
        nodes {
          text
          author {
            name
            username
            avatar
          }
          tweetURL
        }
      }
    }
  `);

  return (
    <section className={cx('wrapper', 'safe-paddings')}>
      <div className="container">
        <h2 className={cx('title')}>What our users are saying</h2>
        <ul className={cx('items', 'grid-gap')}>
          {items.map(({ text, author: { name, username, avatar }, tweetURL }, index) => (
            <li className={cx('items-item-wrapper')} key={index}>
              <Link className={cx('items-item')} to={tweetURL} target="_blank" rel="noopener noreferrer">
                <div className={cx('items-item-corner')} />
                <div className={cx('items-item-inner')}>
                  <div className={cx('items-item-author')}>
                    <div className={cx('items-item-author-avatar-wrapper')}>
                      <img
                        className={cx('items-item-author-avatar')}
                        height={56}
                        width={56}
                        src={avatar}
                        alt={name}
                        loading="lazy"
                      />
                      <TwitterLogo className={cx('items-item-twitter')} />
                    </div>
                    <div className={cx('items-item-author-info')}>
                      <h3 className={cx('items-item-author-name')}>{name}</h3>
                      <span className={cx('items-item-author-username')}>{username}</span>
                    </div>
                  </div>
                  {/* eslint-disable-next-line react/no-danger */}
                  <p className={cx('items-item-text')} dangerouslySetInnerHTML={{ __html: text }} />
                </div>
              </Link>
            </li>
          ))}
        </ul>
        <div className={cx('view-all-link-wrapper')}>
          <ArrowLink to="/testimonials/">View all testimonials</ArrowLink>
        </div>
        <br />
        <br />
      </div>
    </section>
  );
};

export default Testimonials;
